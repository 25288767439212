.score-card {
    background-color: #1a1818;
    height: 87vh;
    /* padding-left: 5px; */
    border-radius: 5px;
    overflow-y: scroll;



}


.score-card::-webkit-scrollbar {
    display: none;
}

.match-name-div {
    min-width: 250px;
    width: auto;
    /* background-color: aqua; */
    height: 60px;
    max-width: auto;
    /* max-width: auto; */
}

.match-name-btn {

    padding: 10px 10px 10px 10px;
    height: auto;
    color: #333;
    /* width: 90%; */
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    /* line-height: initial; */
    display: inline-block;

    /* position: absolute; */
    margin-top: 15px;
    /* top: 5px; */
    /* left: 200px; */
    background-color: #ff8a0c;
    align-items: center;
    /* display: flex; */
    /* justify-content: center; */
    border-radius: 25px;
    /* z-index: 1; */
    -webkit-animation: puls 3s linear infinite;
    animation: puls 3s linear infinite;
    background: linear-gradient(to right, #f76904 0, #ff8c30 15%, #ff9b30 30%, #f78b04 55%, #ff8a0c 100%);
    color: #FFF;
    /* text-shadow: 1px 1px 3px rgb(0 0 0 / 50%); */
    word-spacing: 3px;
    border: none !important;

}

hr {

    background: linear-gradient(to right, #f76904 0, #ff8c30 15%, #ff9b30 30%, #f78b04 55%, #ff8a0c 100%);
    color: linear-gradient(to right, #f76904 0, #ff8c30 15%, #ff9b30 30%, #f78b04 55%, #ff8a0c 100%);
    height: 2px;
    border: none !important;
    margin-bottom: 100px;
    /* border-top:1.5 px solid #ff8a0c; */
    /* position: relative; */
    /* border-top-left-radius: 5px;
        border-top-right-radius: 5px; */
    /* line-height: 1.5;
        padding-top: 60px; */
    /* display: flex; */
    /* justify-content: center; */
    /* display: block; */


}

.mn {
    color: #f78b04;
    font-family: "mukta";
    font-weight: 500;
    font-size: 17px;
}

.vs {
    color: #ED213A;
}

.in-play-main-div {
    color: white;
}

.blue-btn {
    border: none;
    background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
}

.main-score-card-div {
    min-height: 150px;
    width: 100%;
}

.batting-icon {
    background-color: #f1e6e6;
    color: white;
    border-radius: 50px;
    width: 52px;
    height: 52px;
}

.bat-icon-img {
    height: 33px;
}

.team-a-name {
    font-size: 27px;
    color: white;
    font-family: "mukta";
    font-weight: 700;

}

.A-team-score {
    font-size: 10px;
    color: white;
}

.bd {
    border: 1px solid white;
}

.stumps {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    font-family: "mukta";
    font-weight: 600;

}

.batter-info {
    color: white;
    font-size: 12px;
}

.over-ball-div {
    height: 60px;
    border-radius: 25px;
    background-color: rgb(224, 217, 217);
    width: 70%;

}

.single-info {
    background-color: lightseagreen;
    border-radius: 20px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";

}

.red-info {
    background-color: red;
    border-radius: 20px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";

}

.four-info {
    background-color: rgb(98, 158, 7);
    border-radius: 20px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";

}

.back-lay-row {
    height: 10px;
}

.six-info {
    background-color: rgb(253, 228, 2);
    border-radius: 20px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";


}

.six1-info {
    background-color: blue;
    border-radius: 20px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";


}


.favourite {
    color: rgb(230, 230, 12);
    font-size: 13px;
    font-weight: 600;
    font-family: "mukta";
}

.no-run-info {

    background-color: rgb(187, 180, 180);
    border-radius: 20px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "mukta";


}

.betting-info {
    border-bottom: 2px solid #ff8a0c;

}

.betting-info-btn {
    /* background-color: #cfc3c3; */
    border: none;
    height: 37px;
    color: white;
    font-size: 14px;
    font-family: "mukta";



}

.Match-ods-h {
    background-color: #333;
    font-family: "mukta";
    font-weight: 700;
    height: 35px;
    color: white;
}

.betting-team-info {
    margin-top: 20px;
    border-top: 1px solid rgb(146, 137, 137);
    background-color: #0f0e0e;
    height: auto;
    font-family: "mukta";

}

.back-lay-row_1 {
    height: 30px;
}

.betting-team-info_1 {
    /* margin-top: 20px; */
    border-top: 1px solid rgb(146, 137, 137);
    background-color: #0f0e0e;
    height: auto;
    font-family: "mukta";

}


.inplay-w {
    font-size: 13px;
    font-family: "mukta";
    font-weight: 500;
    white-space: nowrap;

    text-align: center;
}

.lead {
    color: white;
    font-size: 14px;
    font-family: "mukta";
}

.green-live-dot {
    height: 7px;
    width: 7px;
    background-color: green;
    border-radius: 5px;
}

.betting-t-n {
    color: white;
    font-size: 14px;
    font-weight: 600;



}

.back {
    background-color: #089CF1;
    border: none;
    color: white;
    font-family: "mukta";
    border-radius: 3px;
    width: 65px
}

.lay {
    background-color: #F4913B;
    border-radius: 3px;
    border: none;
    color: white;
    font-family: "mukta";
    width: 65px;
}

.zero {
    color: light greenyellow !important;
}

.blue-bet-btn {
    background-color: #089CF1;
    border: none;
    color: white;
    font-family: "mukta";
    border-radius: 3px;
    width: 65px;
    height: 50px;
    cursor: pointer;
}

.orange-bet-btn {
    background-color: #F4913B;
    border-radius: 3px;
    border: none;
    color: white;
    font-family: "mukta";
    width: 65px;
    height: 50px;
    cursor: pointer;
}

.TV-outer-div {
    background-color: #333;
    height: 87vh;
    border-radius: 5px;

}

.live-tv-card {
    background-color: #292828;
    height: auto;
    border-radius: 5px;

}

.live-tv1 {
    background-color: #292828;
    /* height: 300px; */
    border-radius: 5px;

}


.live-tv-card-active {
    background-color: #292828;
    /* height: 300px; */
    border-radius: 5px;
    ;
}

.tv-card {
    border-bottom: 1px solid #43CEED;


}

.tv-card p {
    color: #43CEED;
    font-size: 14px;
}

.tv-icon {
    color: #ff8a0c !important;
    font-size: 22px;
}

.matched-bets {
    height: 198px;
    background-color: #464343;
    border-radius: 5px;

}

.bet-matches {
    color: #ff8a0c !important;
    font-weight: 600;
    font-family: "mukta";
}

.no-bets {
    color: white;
    font-weight: 600;
    font-size: 13px;
    border-top: 1px solid white;
}

@media screen and (max-width: 600px) {
    .batting-icon {
        background-color: #f1e6e6;
        color: white;
        border-radius: 50px;
        width: 30px;
        height: 28px;
    }

    .bat-icon-img {
        height: 20px;
    }

    .team-a-name {
        font-size: 13px;
        color: white;
        font-family: "mukta";
        font-weight: 500;

    }


}

.sec__bg {
    height: fit-content;
}

.tr_data_1 {
    position: absolute;
    background: #000000d1;
    padding: 9px 13px;
    color: red;
    text-align: center;
    font-size: 18px;

}

.spnd {
    background: #000000d1;
    color: #F4913B;
    border: 2px solid #F4913B;
    border-radius: 5px;
    white-space: nowrap;
}

.pos_re {
    position: relative;
}



.btnn {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 7px;
    padding: 5px 10px;
    margin: 5px;
    border: 2px solid #fff;
    box-shadow: 3px 2px;
    font-style: italic;
    animation: all ease 1s;
}

.btnn-dark {
    background-color: #000;
}

.btnn-red {
    background-color: #f10707;
}


.btnn-green {
    background-color: #2ebb34;
}

.btnn-green:active,
.btnn-red:active {
    scale: 0.9;
}



/* ++++++++++++++++++++++++table score css++++++++++++++++++++++ */

.fon {
    font-size: 14px;
    margin-bottom: 0px;
}

.fon_1 {
    font-size: 17px;
    font-weight: 700;
}

.overs {
    border: 1px solid white;
    border-radius: 50%;
    margin-right: 5px;
    padding: 0px 8px;
    font-size: 10px;
    /* line-height: 21px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: green;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: green;
}

.pads {
    padding: 0px 20px 0px 0px;
}

.padss {
    padding: 14px 20px 15px 20px;
}

.bor {
    border-bottom: 1px solid #797c13;
    padding: 10px 15px 9px 9px;
}

.brd {
    border-bottom: 1px solid #797c13;
    padding: 4px 0px 4px 0px;
}

.mainss {
    display: flex;
    justify-content: space-between;
}

.dsi {
    display: flex;
    justify-content: space-between;
    overflow: auto;
}

.uls {
    list-style: none;
    display: flex;
    padding-left: 0;
    padding-right: 12px;

}

.bordersse {
    border-right: 1px solid white;
    width: 50%;
}

.bgsegreeen {
    background-color: #5f9a24;
    height: 100%;
}

.bgsegreen {
    background-color: #5f9a24;
}

.bgssgreen {
    background-color: #334932;
    padding: 5px 10px;
    text-align: center;
}

.butte {
    background-color: green;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    border: none;
    color: white;
    border-radius: 10px;

}

/* ++++++++++++++++++++++++table score css end++++++++++++++++++++++ */
/* Table  maintableD Start  */
.maintableD {
    background-color: #333333;
}

.maintableD table {
    table-layout: fixed;
}

.maintableD table thead tr th {
    color: white;
    font-size: 12px;
    text-align: start !important;
}

.maintableD table tbody tr th {
    color: white;
    font-size: 14px;
    text-align: start !important;
}

.maintableD table tbody tr td {
    color: white;
    font-size: 14px;
    text-align: start !important;
    white-space: nowrap;
    text-overflow: inherit;
    overflow: hidden;
}

/* Table  maintableD End  */



.count {
    background-color: #334932;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    margin: 3px;
}

.fone {
    font-size: 12px;
}

@media (max-width:576px) {
    #frame_video_21 {
        height: 100%;
    }
}

@media (min-width:576px) {
    #frame_video_21 {
        height: 400px;
    }
}

.blink-green {
    background-color: #16c412;
    transition: background-color 1s ease-in-out;
}