.funds-transfer-main-div {
  background-color: rgb(20, 19, 19);
  /* height: 86vh; */
  width: 100%;
  border-radius: 5px;
}
.F-h {
  font-weight: 700;
  color: white;
  font-size: 20px;
}
.form-area {
  border-top: 1px solid rgb(66, 61, 61);
}
.cp-card {
  height: 433px;
  overflow: auto;
  /* width: 350px; */
  background-color: black;
  border: 1px solid rgb(99, 96, 96);
  color: white;
}
.balance-div {
}
.balance-h {
  color: white;
  font-size: 14px;
}
.b-o {
  color: #fa5004;
  font-size: 17px;
  font-weight: 600;
  font-family: "mukta";
}
label {
  font-size: 11px;
}
.user-id-input {
  background-color: rgb(102, 146, 146);
}

.transfer-btn {
  width: 100%;
  background-color: #fa5004;
  color: white;
}
@media screen and (max-width: 490px) {
  .deposit-card {
    height: 450px;
    width: 340px;
    background-color: black;
    border: 1px solid rgb(99, 96, 96);
    color: white;
    overflow: auto;
  }
  .buy_token {
    background: #000000;
    border: 1px solid #fa5004;
    box-shadow: 4px 2px 6px rgb(0 0 0 / 51%),
      inset -2px -2px 6px rgb(0 0 0 / 34%);
    padding: 15px;
    /* height: 140px; */
    width: 100% !important;
    text-align: center;
    border-radius: 10px;
    /* border: 1px solid transparent; */
  }
}
