nav {
  border-bottom: 1px solid #fd740d !important;
  background-color: black;

}
@media only screen and (max-width: 600px) {
  
.navbar {
  height:15vh !important;
}
.navbar-brand {
  height: 69px !important;
}
}
.nav-bg{
  background: linear-gradient(to right, #c33764, #1d2671);
}
.menucol{
  background-color: red !important;
    font-size: 10px;
    padding: 7px 7px 0px 10px !important;
    margin: 10px 10px 0px 10px !important;
    border-radius: 10px;
}
.white-space-nowrap {
  white-space: nowrap;
}

.navbar {
  height: 12vh;
}

.navbar-brand {
  height: 75px;
}

@media only screen and (max-width: 600px) {
  .loginsia{
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
  .signin-btn {
    /* padding: 6px 20px; */
    height: 28px !important;
   
    width: 100% !important;
    border-radius: 6px;
    border: none !important;
    color: white;
    font-size: 16px;
    background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
  }

  .wallet-btn {
    height: 28px !important;
   
    width: 100% !important;
    border-radius: 6px;
    background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
    color: white;
    border: none;
  }

  .schedule-btn {
    background-color: #5a5858;
    height: 47px !important;
    text-align: center;
    width: 94px !important;
    color: white;
    font-size: 11px;
    border-radius: 5px;
  }

  .in-play-btn {
    background-color: #030303;
    height: unset !important;
    width: 112px !important;
    color: #fd7e14;
    border: 1px solid #fd7e14;
    font-size: 10px;
    border-radius: 5px;
  }
}

.signin-btn {
  /* padding: 6px 20px; */
  height: 36px;
  width: 90px;
  border-radius: 6px;
  border: none !important;
  color: white;
  box-shadow: 0px 0px 5px 3px;
  font-size: 16px;
  background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
}

.wallet-btn {
  height: 36px;
  width: 90px;
  border-radius: 6px;
  background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
  color: white;
  border: none;
  box-shadow: 0px 0px 5px 3px;

}

.nav-btn {
  /* background-color: rgb(82, 81, 81); */
  background-color: #333333;
  padding: 6px 20px;
  border-radius: 6px;
  border: none !important;
  color: white;
  font-size: 13px;
  margin-left: 4px;
  font-family: "mukta";
  font-weight: 600;
  text-decoration: none;

}

.nav-btn:hover {
  background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
}

.nav-btn.is-active {
  background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
}



/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;

}


.dropbtn:hover,
.dropbtn:focus {}


.dropdown {
  position: relative;
  display: inline-block;

}


.dropdown-content {
  /* display: none; */
  position: absolute;
  right: 30%;
  background-color: #202020;
  /* min-width: 170px; */
  /* height: 70px; */
  font-size: 16px;
  font-weight: 600px;
  /* height: 20px; */
  white-space: nowrap;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 9px 20px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  display: block;
  border: 1px solid black;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #1ea9c9;
}


.dropdown-content svg {
  color: #ffffff;
  /* padding: 9px 20px; */

  font-size: 16px;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  /* background-color: #2980b9; */
}

.person-icon-div {
  background-color: #fa5004;
  border-radius: 17px;
  color: black;
  height: 28px;
  width: 28px;

}

.p-icon {
  /* font-size: 15px; */
}