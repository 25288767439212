.home{
    overflow-y: scroll;
    height:100%;
    /* background-color: aqua !important; */
}
.home::-webkit-scrollbar{
    display:none;
}

/* =============++++++++++++Dash Image css++++++++++++++==================== */

/* .bootomcards{
    border-top: 2px dashed #fa5004;
} */
.bootomcards .casino-list{
    display: flex;
    flex-wrap: wrap;
}
.bootomcards .casino-list .casino-list-item{
    width: calc(10% - 10px);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.bootomcards .casino-list .casino-list-item .casino-list-item-banner{
    width: 100%;
    height: 150px;
    border: 5px solid white;
    border-radius:5px;

}
.casino-list-name{
    background-color: #fa5204e4;
    color: white;
    /* padding: 5px; */
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 4px;
    font-size: 10px;
}
@media screen and (min-width: 320px) and (max-width: 767.99px) {
    .casino-list-item {
        width: calc(32% - 6px) !important;
    }
    .bootomcards .casino-list{
        justify-content: center;
    }
  }
@media screen and (min-width: 768px) and (max-width: 1199.99px) {
    .casino-list-item {
        width: calc(16.66% - 10px) !important;
    }
  }
@media screen and (min-width: 1200px) and (max-width: 1392.99px) {
    .casino-list-item {
        width: calc(13.8% - 10px) !important;
    }
  }
  
      