.sb-h{
    border-bottom: 1px solid #43CEED;
    font-size: 14px;
    color:#43CEED;
    font-family: "mukta";
    font-weight: 600;
  }
  .side-bar{
    background-color: #202020;
   height : 88vh; 
    overflow-y: scroll;
    
  }
  .side-bar::-webkit-scrollbar{
    display:none;
}
.sm-side-bar{
 
  top:3%;
  z-index: 999999;

}
  .list-group{
    background-color: #202020;
  
  }
  .list-group-item{
    background-color: #202020;
    border: none !important;
    border-bottom: 1px solid grey !important;
    color: white !important;
    font-size: 14px;
    font-family: "mukta";
    font-weight: 600;
    height: 45px;
  
  }
  .list-group-item:hover{
    /* translate: 3px 0px 0px; */
    transition: .5s ease;
    border-left: 2px solid #fa5004 !important;
  }
  .side-icon{
    height: 20px;
    color: white !important;
    /* background-color: white; */
    border: none !important;
    opacity: 1;
  }
  .menu{
    background-color: #3b3838;
    color: white;
    font-family: "mukta";
    font-weight: 500;
    border-top: 1px solid grey;
    min-height :39px;
    max-height: auto;
    font-size: 15px;
    transition: .5s ease;
    cursor: pointer;
  }
  .subMenu{
    height: auto;
    font-size: 15px;
    font-weight: 600;
    font-family: "mukta";
    border-top: 1px solid white;
    /* background-color: #333030; */
    padding: 5px;
    cursor: pointer;

  }
  .menu-icon{
    color:white;
    font-size: 19px;
    /* transition: all .2s ease-out; */
  }
  /* <---------------------------------->sdebardropdown>>>>>>>>>>>>>>>>>>>> */
  .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width:100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }
 

/* <-------------------------------------------------------------------------------------------------->>>>>> */


  
  
  @media screen and (max-width: 550px){
    .side-bar{
        width: 200px;
        
        /* position: absolute; */
        /* z-index: 9999; */
        /* top: 40; */
        /* bottom: 30; */
    }


}

  