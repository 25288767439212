body {
    background-color: rgb(43, 38, 38);
    color: white;
    font-family: Arial, sans-serif;
}
.dis{
    display: flex;
    justify-content: space-between;
}
.form-elm{
    width: 100%;
}
.wids{
width: 15%;
}
.bg-red{
    border: none;
    color: white;
    background-color: red;
    padding: 10px;
    border-radius: 5px;
}
.flexbox{
    display: flex;
}
select {
    /* padding: ; */
    font-size: 16px;
    height: 37px;
    border: none;
    background-color: rgb(44, 40, 40);
    color: white;
    /* width:100%; */
}
option {
    background-color: black;
    color: white;
    /* padding-top: 0; */

}