.amerakbarantho .casino-table {
    background-color: var(--bg-table);
    color: var(--text-table);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
}

.amerakbarantho .casino-table-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.aaa .casino-odd-box-container {
    width: calc(33.33% - 7.5px);
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aaa .casino-odd-box-container {
    width: calc(33.33% - 7.5px);
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aaa .casino-nation-name {
    width: 100%;
    text-align: center;
}

.aaa .casino-odds-box {
    width: 49%;
}

.aaa .casino-odd-box-container:last-child {
    margin-right: 0;
}

.aaa .casino-table-left-box,
.aaa .casino-table-center-box,
.aaa .casino-table-right-box {
    width: 32%;
    padding: 10px 10px 0 10px;
}

.aaa .aaa-odd-box {
    margin-bottom: 10px;
    min-height: 92px;
}

.aaa .casino-table-left-box .casino-odds-box,
.aaa .casino-table-center-box .casino-odds-box,
.aaa .casino-table-right-box .casino-odds-box {
    width: 100%;
    margin: 5px 0;
}

.aaacards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: 0;
    padding: 10px 10px 0 10px;
}

.smallimage {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardimage {
    width: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
    .aaa .casino-odd-box-container {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid #c7c8ca;
    }

    .aaa .casino-nation-name {
        width: 60%;
        text-align: left;
        padding-left: 5px;
    }

    .aaa .casino-odds-box {
        width: 20%;
    }

    .amerakbarantho .casino-odds {
        font-size: 13px;
    }
    .amerakbarantho .innerrow{
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.3rem;
    }
}