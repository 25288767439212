.worlibox {
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
    position: relative;
    width: 100%
}

.worli-left {
    width: 60%;
    display: flex;
    flex-wrap: wrap
}

.worli-box-title {
    width: 100%;
    text-align: center;
    margin: 5px 0;
    min-height: 24px
}

.worli-box-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px
}

.worli-odd-box {
    text-align: center;
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer
}

.worli-left .worli-odd-box,.worli-full .worli-odd-box {
    width: calc(20% - 2px);
    margin-right: 2px
}

.worli-odd-box .worli-odd {
    font-size: 40px;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: casinonumfont
}

.worli-right {
    width: 40%;
    display: flex;
    flex-wrap: wrap
}

.worli-right .worli-odd-box {
    width: calc(50% - 2px);
    margin-right: 2px
}

.worli-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px
}

.worli-odd-box.back.selected {
    background-color: var(--bg-success)!important;
    color: #fff
}

.sp .worli-right .worli-odd-box,.dp .worli-right .worli-odd-box,.chart56 .worli-right .worli-odd-box,.chart64 .worli-right .worli-odd-box,.colordp .worli-right .worli-odd-box {
    height: 142px;
    width: 100%
}

.trio .worli-full .worli-odd-box {
    width: 100%
}

.abr .worli-left .worli-odd-box {
    width: calc(33.33% - 2px)
}

.abr .worli-right .worli-odd-box {
    width: 100%
}

.abr .worli-right .worli-odd-box:last-child {
    margin-right: 0
}

.abr .worli-right .worli-odd-box:last-child {
    margin-right: 0
}

.worli-cards-container {
    display: flex;
    flex-wrap: wrap
}

.worli-cards-block {
    width: calc(10% - 9px);
    text-align: center;
    background-color: var(--bg-secondary85);
    padding: 10px;
    font-size: 20px;
    color: var(--text-secondary);
    border-radius: 8px;
    box-shadow: 0 0 2px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center
}

.worli-cards-block:nth-child(10n) {
    margin-right: 0
}

.worli-cards-block span {
    margin-right: 5px
}