/* Race t 20 card start */
.middlecardracet .casino-table {
    background-color: #f7f7f7;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
}

.middlecardracet .casino-odd-box-container:last-child {
    margin-right: 0;
}

.middlecardracet .casino-table .casino-table-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.middlecardracet .casino-odd-box-container {
    width: calc(25% - 7.5px);
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* .middlecardracet .casino-nation-name {
    width: 100%;
    text-align: center;
}

.middlecardracet .casino-nation-name {
    font-size: 16px;
    font-weight: bold;
} */

.middlecardracet .casino-nation-name img {
    height: 40px;
    margin-bottom: 5px;
}

.middlecardracet .casino-odds-box {
    width: 49%;
}

.middlecardracet .casino-odds-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    font-weight: bold;
    border-left: 1px solid #c7c8ca;
    cursor: pointer;
    min-height: 46px;
}

.middlecardracet .suspended-box {
    position: relative;
    pointer-events: none;
    cursor: none;
}

.middlecardracet .back {
    background-color: #72bbef !important;
}

.middlecardracet .suspended-box::before {
    background-image: url(../../../src/Media/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    -webkit-filter: invert(1);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    pointer-events: none;
}

.middlecardracet .suspended-box::after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.middlecardracet .casino-odds {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
}

.middlecardracet .casino-volume {
    font-size: 12px;
    font-weight: 100;
    line-height: 1;
    margin-top: 5px;
}

.middlecardracet .casino-nation-book {
    font-size: 12px;
    font-weight: bold;
    min-height: 18px;
    z-index: 1;
}

.middlecardracet .casino-table-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.middlecardracet .casino-table-left-box {
    width: 33%;
    padding: 5px 5px 0 5px;
}

.casino-table-left-box,
.casino-table-center-box,
.casino-table-right-box {
    width: 49%;
    border-left: 1px solid #c8c9cb;
    border-right: 1px solid #c8c9cb;
    border-top: 1px solid #c8c9cb;
    background-color: #f2f2f2;
}

.middlecardracet .casino-table-left-box .casino-odd-box-container {
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}

.middlecardracet .casino-table-left-box .casino-odd-box-container .casino-nation-name,
.middlecardracet .casino-table-left-box .casino-odd-box-container .casino-odds-box {
    width: 33.33%;
}

.middlecardracet .casino-nation-name {
    width: 100%;
    text-align: center;
}

/* .middlecardracet .casino-nation-name {
    font-size: 16px;
    font-weight: bold;
} */

/* .middlecardracet .casino-table-left-box .casino-odd-box-container .casino-nation-name,
.middlecardracet .casino-table-left-box .casino-odd-box-container .casino-odds-box {
    width: 33.33%;
} */

.middlecardracet .casino-odds-box {
    width: 49%;
}

.middlecardracet .suspended-box {
    position: relative;
    pointer-events: none;
    cursor: none;
}

.middlecardracet .lay {
    background-color: #faa9ba !important;
}

.middlecardracet .suspended-box::before {
    background-image: url(../../../src/Media/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    -webkit-filter: invert(1);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    pointer-events: none;
}

.middlecardracet .casino-odds {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
}

.middlecardracet .casino-volume {
    font-size: 12px;
    font-weight: 100;
    line-height: 1;
    margin-top: 5px;
}

.middlecardracet .casino-table-left-box .casino-odd-box-container>.casino-nation-book {
    width: 66.66%;
    text-align: center;
    margin-left: auto;
    margin-top: 5px;
}

/* .middlecardracet .casino-odd-box-container {
    width: 100%;
} */
.middlecardracet .casino-nation-book {
    font-size: 12px;
    font-weight: bold;
    min-height: 18px;
    z-index: 1;
}

element.style {}

/* .middlecardracet .casino-table-left-box .casino-odd-box-container .casino-nation-name,
.middlecardracet .casino-table-left-box .casino-odd-box-container .casino-odds-box {
    width: 33.33%;
} */

/* .middlecardracet .casino-nation-name {
    width: 100%;
    text-align: center;
} */
/* .middlecardracet .casino-nation-name {
    font-size: 16px;
    font-weight: bold;
} */
/* @media only screen and (max-width: 1199px) {
    .middlecardracet .casino-nation-name {
        font-size: 12px;
    }
} */

.middlecardracet .casino-table-right-box {
    width: 66%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 0 5px;
}

.middlecardracet .casino-table-left-box,
.casino-table-center-box,
.casino-table-right-box {
    width: 49%;
    border-left: 1px solid #c8c9cb;
    border-right: 1px solid #c8c9cb;
    border-top: 1px solid #c8c9cb;
    background-color: #f2f2f2;
    border-bottom: 1px solid #c8c9cb;
}

.middlecardracet .casino-table-right-box .casino-odd-box-container {
    width: calc(33.33% - 10px);
}

/* .middlecardracet .casino-nation-name {
    width: 100%;
    text-align: center;
} */

@media only screen and (max-width: 1199px) {
    .middlecardracet .casino-nation-name {
        font-size: 12px;
    }
}

.middlecardracet .casino-nation-name {
    font-size: 16px;
    font-weight: bold;
}

.middlecardracet .casino-table-left-box {
    width: 33%;
    padding: 5px 5px 0 5px;
}

@media only screen and (max-width: 767px) {
    .middlecardracet .casino-odd-box-container {
        width: calc(46% - 0px);
    }
}

@media only screen and (max-width: 767px) {
    .middlecardracet .casino-table-left-box {
        width: 100%;
    }

    .middlecardracet .casino-table-right-box {
        width: 100%;
    }

    .middlecardracet .casino-nation-name {
        font-size: 13px;
    }

    .middlecardracet .casino-odd-box-container {
        margin-right: 0px;
    }
}

.mainmodal .modalbody {
    max-height: calc(98vh - 50px);
    /* height: 100vh; */
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.mainmodal .modalbody .casino-result-modal {}

.mainmodal .modalbody .casino-result-modal .casino-result-round-id {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

}

.mainmodal .modalbody .casino-result-modal .casino-result-round-id span {
    font-size: 14px;
    color: white;
}
.mainmodal .race-result-box .casino-result-cards {
    position: relative;
    justify-content: flex-start !important;
}
.mainmodal .race-result-box {
    width: 325px;
    position: relative;
}

.mainmodal .race-result-box .casino-result-cards {
    position: relative;
    justify-content: flex-start;
}

.mainmodal .casino-result-modal .casino-result-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.mainmodal .casino-result-modal .casino-result-cards img {
    height: 50px;
    width: auto;
    margin-right: 5px;
}

.mainmodal .race-result-box .casino-result-cards .k-image {
    position: absolute;
    right: -50px;
}

.mainmodal .race-result-box .casino-winner-icon {
    right: -95px;
    top: 15px;
    position: absolute;
}

.mainmodal .casino-winner-icon {
    margin: 0 10px;
}

.mainmodal .casino-winner-icon i {
    font-size: 26px;
    box-shadow: 0 0 0 var(--bg-secondary);
    animation: winnerbox 1.5s infinite;
    border-radius: 50%;
    color: #169733;
}

.mainmodal .race-result-box .video-winner-text {
    color: #097c93;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    width: 42px;
    height: 100%;
    border: 1px solid #097c93;
    padding: 2px 12px;
    background-color: #ffffff;
    z-index: 100;
}
.mainmodal .casino-result-modal .row [class*="col-"]:last-child{
    border-right: 0;
}
.mainmodal .casino-result-modal .row [class*="col-"] {
    border-right: 1px solid #bfbfbf;
    position: relative;
}
.mainmodal .casino-result-desc {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding: 6px;
    box-shadow: 0 0 4px -1px;
    margin-top: 10px;
}
.mainmodal .casino-result-desc-item {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.mainmodal .casino-result-desc-item div:first-child {
    margin-right: 2%;
    opacity: 0.6;
}
.mainmodal .casino-result-desc-item div:last-child {
    word-break: break-all;
}
.modalmainhight{
    height: 400px;
    top: 35%;
}
@keyframes winnerbox {
    0% {
        box-shadow: 0 0 0 0 rgba(29, 127, 30, 0.6);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(29, 127, 30, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(29, 127, 30, 0);
    }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .mainmodal .race-result-box .video-winner-text{
        font-size: 19px;
        width: 42px;
    }
    .mainmodal .race-result-box{
        width: 300px;
    }
    .mainmodal .casino-result-modal .casino-result-cards img{
        width: 36px;
        height: 40px;
    }
}
/* race t 20 card End */
@media screen and (min-width: 320px) and (max-width: 768px) {
    .border_bottom {
        border-bottom: 1px solid #474747;
    }
}